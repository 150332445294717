<template>
  <a-modal
    v-model="visible"
    class="modal-user pick-extras"
    :title="productName"
    :maskClosable="false"
  >
    <div v-if="hasProduct" class="product-descriptions">
      <a-form :form="form">
        <a-row type="flex" class="add-units">
          <p>{{ productDescription }} </p>
          <template v-if="hasExtras"> →
            <div class="tag-units">Unidades</div>
            <a-icon type="minus-circle-o" @click="editUnits(0)"/>
            <div class="total-units"><span>{{ totalUnits }}</span></div>
            <a-icon type="plus-circle-o" @click="editUnits(1)"/>
          </template>
        </a-row>

        <div class="product-selected-qty" v-if="hasProduct && !isProductWithPrice">
          <a-form-item
            label="Precio"
          >
            <a-input-number
              :min="1"
              :max="1000"
              placeholder="Introduce el precio"
              :formatter="
                value  => `$ ${value}`.replace(/\./g, '')
              "
              :step="1"
              v-decorator="[
                'selected_qty',
                {
                  rules: [
                    {
                      required: true,
                      message: `El campo precio es requerido.`,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </div>

        <div class="product-options">
          <div
            class="option"
            v-for="extra in option.product.options"
            :key="extra.id"
          >
            <a-form-item
              :label="extra.list.description"
              v-if="isUnique(extra.list)"
            >
              <a-radio-group
                v-decorator="[
                  `radio${extra.list.id}`,
                  {
                    rules: [
                      {
                        required: isRequired(extra.list),
                        message: `El campo ${extra.list.description} es requerido.`,
                      },
                    ],
                  },
                ]"
              >
                <div
                  class="option-details"
                  v-for="details in extra.list.extras"
                  :key="details.id"
                >
                  <a-row type="flex">
                    <a-col :span="12">
                      <p>{{ details.name }}</p>
                      <!-- {{ isNotAvailable(details) ? "No disponible" : "" }} -->
                    </a-col>
                    <a-col class="option-cost" :span="12">
                      <div>
                        <a-radio
                          :disabled="isNotAvailable(details)"
                          :value="details.name"
                          @change="
                            addExtra(
                              $event,
                              extra.list,
                              details,
                              0,
                              `radio${extra.list.id}`
                            )
                          "
                          ><p>{{ getExtraCost(details) }}</p></a-radio
                        >
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </a-radio-group>
            </a-form-item>
            <a-form-item :label="extra.list.description" v-else>
              <p class="option-limit">
                Seleccione entre {{ getMinExtra(extra.list) }} y
                {{ extra.list.limit }} opciones
              </p>
              <a-checkbox-group
                v-decorator="[
                  `checkbox${extra.list.id}`,
                  {
                    rules: [
                      {
                        required: isRequired(extra.list),
                        message: `El campo ${extra.list.description} es requerido.`,
                      },
                    ],
                  },
                ]"
              >
                <div
                  class="option-details"
                  v-for="details in extra.list.extras"
                  :key="details.id"
                >
                  <a-row type="flex">
                    <a-col :span="12">
                      <p>{{ details.name }}</p>
                    </a-col>
                    <a-col class="option-cost" :span="12">
                      <div>
                        <a-checkbox
                          :disabled="
                            isNotAvailable(details) ||
                              (getComboboxDisabledStatus(extra.list) &&
                                !isSelected(
                                  `checkbox${extra.list.id}`,
                                  details.id
                                ))
                          "
                          :value="details.id"
                          @change="
                            addExtra(
                              $event,
                              extra.list,
                              details,
                              1,
                              `checkbox${extra.list.id}`
                            )
                          "
                          ><p>{{ getExtraCost(details) }}</p></a-checkbox
                        >
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </a-checkbox-group>
            </a-form-item>
          </div>
        </div>
      </a-form>
    </div>
    <template slot="footer">
      <a-button key="back" @click="goBack">
        Atrás
      </a-button>
      <a-button key="create" @click="submitAddData" type="primary">
        Agregar
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { formatNumbers, productStatus } from "@/utils/utils.js";

export default {
  name: "PickExtras",
  components: {},
  props: {
    isElectronXpress: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    option: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      totalUnits: 1,
      optional: 1,
      extras: [],
      selectedExtras: [],
      inputs: [],
      form: this.$form.createForm(this, {
        name: "coordinated",
      }),
      checkboxDisabled: false,
    };
  },
  computed: {
    productName() {
      return this.option.product != null ? this.option.product.name : "";
    },
    productDescription() {
      return this.option.product.description;
    },
    hasExtras() {
      return productStatus.hasExtras(this.option.product);
    },
    hasProduct() {
      return this.option.product != null;
    },
    isProductWithPrice() {
      return productStatus.isProductWithPrice(this.option.product);
    },
    getSelectedExtras() {
      return this.selectedExtras.length;
    },
  },
  methods: {
    editUnits(type) {
      if (type === 1) {
        this.totalUnits++;
      } else if(this.totalUnits > 1) {
        this.totalUnits--;
      }
    },
    getExtraCost(extra) {
      const extraCost = formatNumbers.getExtraCost(extra, this.isElectronXpress);
      return extraCost ? `$${extraCost}` : "";
    },
    isSelected(checkboxId, id) {
      const formData = this.form.getFieldValue(checkboxId);
      if (typeof formData !== "undefined") {
        const status = typeof formData.find((e) => e === id) !== "undefined";
        return status;
      }
      return false;
    },
    getComboboxDisabledStatus(extra) {
      const ex = this.selectedExtras.find(e => e.id === extra.id);
      return typeof ex !== "undefined" ? ex.disabled : false;
    },
    submitAddData() {
      this.form.validateFields((err) => {
        if (!err) {
          this.selectExtras();
        }
      });
    },
    isNotAvailable(extra) {
      return extra.available === 0;
    },
    isRequired(product) {
      return product.required === 1;
    },
    getMinExtra(product) {
      if (this.isRequired(product)) {
        return 1;
      }
      return 0;
    },
    isUnique(product) {
      return product.behavior === "unique" ? true : false;
    },
    goBack() {
      this.$emit("close");
    },
    selectExtras() {
      if(this.extras.length > 0 && this.extras.extras.length > 0) {
        this.extras.extras[0].extras[0].extra_group = {
            id: this.extras.extras[0].id
        }
      }
      if (!this.isProductWithPrice) {
        this.extras.selectedQty = this.form.getFieldValue("selected_qty");
      }

      this.extras.totalUnits = this.totalUnits;
      this.$emit("add", this.extras);
      this.$emit("close");
      this.extras = {extras:[]};
    },
    addExtra(e, extra, product, type) {
      const extraStatus = this.selectedExtras.filter((e) => e.id === extra.id);
      if (this.selectedExtras.length === 0 || extraStatus.length === 0) {
        const extraCategory = {
          id: extra.id,
          name: extra.description,
          extras: [],
          disabled: false,
        };
        this.selectedExtras.push(extraCategory);
      }
      let extraProduct = this.selectedExtras.find((e) => e.id === extra.id);
      if (type === 1) {
        if (extraProduct.extras.length < extra.limit || extra.limit === 0 || !e.target.checked) {
          if (e.target.checked) {
            this.selectedExtras = this.selectedExtras.map(function(e) {
              if (e.id === extra.id) {
                e.extras.push(product);
              }
              return e;
            });
          } else {
            this.setComboboxDisabledStatus(extra, false);
            this.selectedExtras = this.selectedExtras.map(function(e) {
              if (e.id === extra.id) {
                e.extras = e.extras.filter((ex) => ex.id !== product.id);
              }
              return e;
            });

            extraProduct = this.selectedExtras.find((e) => e.id === extra.id);
          }
          if (extraProduct.extras.length === extra.limit) {
            this.setComboboxDisabledStatus(extra, true);
          }
        } else {
          this.checkboxDisabled = true;
        }
      } else {
        this.selectedExtras = this.selectedExtras.map(function(e) {
          e.extras = [];
          if (e.id === extra.id) {
            e.extras.push(product);
          }
          return e;
        });
      }
      this.onReceiveSelection(this.selectedExtras);
    },
    setComboboxDisabledStatus(extra, status) {
      this.selectedExtras = this.selectedExtras.map(function(e) {
        if (e.id === extra.id) {
          e.disabled = status;
        }
        return e;
      });
    },
    onReceiveSelection(value) {
      this.extras = {
        extras: value,
      };
    },
  },
};
</script>
